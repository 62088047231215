import { environment } from 'config/environment';

const accountPage = `${environment.SHOP_URL}/account`;
const shopPage = `${environment.SHOP_URL}/shop`;
const legalPage = `${environment.SHOP_URL}/legal`;
const tradePage = `${environment.SHOP_URL}/trade`;

export const storefrontRoutePaths = {
  about: `${environment.SHOP_URL}/about`,
  account: {
    addresses: `${accountPage}/addresses`,
    home: accountPage,
    orders: `${accountPage}/orders`,
    payments: `${accountPage}/payments`,
    settings: `${accountPage}/settings`,
    wishlist: `${accountPage}/wishlist`,
  },
  careers: `${environment.SHOP_URL}/careers`,
  creators: `${environment.SHOP_URL}/creators`,
  design: `${environment.SHOP_URL}/design`,
  designer: {
    ammunition: `${environment.SHOP_URL}/ammunition`,
    chrisGranneberg: `${environment.SHOP_URL}/chrisgranneberg`,
    muka: `${environment.SHOP_URL}/muka`,
  },
  giftCards: `${environment.SHOP_URL}/gift-cards`,
  home: `${environment.SHOP_URL}/`,
  latestReleases: `${shopPage}/pendants`,
  legal: {
    privacy: `${legalPage}/privacy`,
    terms: `${legalPage}/terms`,
  },
  made: `${environment.SHOP_URL}/made`,
  materials: `${environment.SHOP_URL}/materials`,
  press: `${environment.SHOP_URL}/press`,
  process: `${environment.SHOP_URL}/process`,
  refer: `${environment.SHOP_URL}/refer`,
  shop: {
    all: shopPage,
    clampLights: `${shopPage}/clamp-lights`,
    floorLights: `${shopPage}/floor-lights`,
    pendantLights: `${shopPage}/pendant-lights`,
    tableLights: `${shopPage}/table-lights`,
    wallLights: `${shopPage}/wall-lights`,
  },
  stories: `${environment.SHOP_URL}/stories`,
  trade: tradePage,
  tradeFaqs: `${tradePage}/faqs`,
} as const;
